import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  catchError, filter,
  finalize, first, map, mapTo, Observable, of,
  shareReplay, switchMap,
  switchMapTo, throwError,
} from 'rxjs';

import { AppError } from '../models/app-error';
import { Login } from '../models/login';
import { PasswordReset } from '../models/password-reset';
import { User } from '../models/user';
import { filterNull } from '../utils/rxjs/filter-null';

import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { UserDto } from './mappers/dto/user.dto';
import { UserMapper } from './mappers/user.mapper';
import { UserSecretStorageService } from './user-secret-storage.service';

/**
 * Stateful service for storing/managing information about the current user.
 */
@Injectable({
  providedIn: 'root',
})
export class UserService {
  /** Current user. Null when user is not logged in. */
  public readonly currentUser$: Observable<User | null>;

  /** Whether the user is authorized. */
  public readonly isAuthorized$: Observable<boolean>;

  private readonly currentUserUrl: URL;

  public constructor(
    appConfig: AppConfigService,
    private readonly httpClient: HttpClient,
    private readonly authService: AuthService,
    private readonly userMapper: UserMapper,
    private readonly router: Router,
    private readonly userSecretStorage: UserSecretStorageService,
  ) {
    this.currentUserUrl = new URL('users/profile/', appConfig.apiUrl);
    this.currentUser$ = this.initCurrentUserStream();
    this.isAuthorized$ = this.currentUser$.pipe(map(user => user != null));
  }

  /**
   * Verify account registration.
   * @param verificationToken Account verification token.
   */
  public verifyAccount(verificationToken: string): Observable<void> {
    return this.authService.verifyAccount(verificationToken).pipe(
      switchMap(secret => this.userSecretStorage.saveSecret(secret)),
      switchMapTo(this.currentUser$),
      filterNull(),
      first(),
      mapTo(void 0),
    );
  }

  /**
   * Login a user with email and password.
   * @param loginData Login data.
   */
  public login(loginData: Login): Observable<void> {
    return this.authService.login(loginData).pipe(
      switchMap(secret => this.userSecretStorage.saveSecret(secret)),
      switchMapTo(this.isAuthorized$),
      filter(isAuthorized => isAuthorized),
      switchMap(() => this.redirectAfterAuthorization()),
    );
  }

  /**
   * Logout current user.
   */
  public logout(): Observable<void> {
    return this.userSecretStorage
      .removeSecret()
      .pipe(finalize(() => this.navigateToAuthPage()));
  }

  /** Update user secret, supposed to be called when user data is outdated. */
  public refreshSecret(): Observable<void> {
    return this.userSecretStorage.currentSecret$.pipe(
      first(),
      switchMap(secret =>
        secret != null ?
          this.authService.refreshSecret(secret) :
          throwError(() => new AppError('Unauthorized'))),

      // In case token is invalid clear the storage and redirect to login page
      catchError((error: unknown) =>
        this.userSecretStorage
          .removeSecret()
          .pipe(
            switchMapTo(this.navigateToAuthPage()),
            switchMapTo(throwError(() => error)),
          )),
      switchMap(newSecret => this.userSecretStorage.saveSecret(newSecret)),
      mapTo(void 0),
    );
  }

  /**
   * Requests to reset the password.
   * @param data Data for resetting the password.
   * @returns Message for the user.
   */
  public resetPassword(data: PasswordReset.Data): Observable<string> {
    return this.authService.resetPassword(data);
  }

  /**
   * Set new password and confirm resetting.
   * @param data Confirm password reset.
   * @returns Success message.
   */
  public confirmPasswordReset(
    data: PasswordReset.Confirmation,
  ): Observable<string> {
    return this.authService.confirmPasswordReset(data);
  }

  private initCurrentUserStream(): Observable<User | null> {
    return this.userSecretStorage.currentSecret$.pipe(
      switchMap(secret => (secret ? this.getCurrentUser() : of(null))),
      shareReplay({ bufferSize: 1, refCount: false }),
    );
  }

  private async redirectAfterAuthorization(): Promise<void> {
    const DEFAULT_REDIRECT_URL = '/';
    const route = this.router.createUrlTree([DEFAULT_REDIRECT_URL]);
    await this.router.navigateByUrl(route);
  }

  private getCurrentUser(): Observable<User> {
    return this.httpClient
      .get<UserDto>(this.currentUserUrl.toString())
      .pipe(map(user => this.userMapper.fromDto(user)));
  }

  private async navigateToAuthPage(): Promise<void> {
    await this.router.navigate(['/auth']);
  }
}
