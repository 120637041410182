import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';

import { AppError } from '../models/app-error';
import { Login } from '../models/login';
import { PasswordChange } from '../models/password-change';
import { PasswordReset } from '../models/password-reset';
import { UserSecret } from '../models/user-secret';
import { catchHttpErrorResponse } from '../utils/rxjs/catch-http-error-response';

import { AppConfigService } from './app-config.service';
import { AppErrorMapper } from './mappers/app-error.mapper';
import { UserSecretDto } from './mappers/dto/user-secret.dto';
import { LoginDataMapper } from './mappers/login-data.mapper';
import { PasswordChangeMapper } from './mappers/password-change.mapper';
import { ResetPasswordConfirmationMapper } from './mappers/reset-password-confirmation.mapper';
import { ResetPasswordMapper } from './mappers/reset-password.mapper';
import { UserSecretDataMapper } from './mappers/user-secret-data.mapper';

interface ResetPasswordConfirmationDto {

  /** Human-readable notification that an operation was successful. */
  readonly detail: string;
}

const AUTH_PREFIX = 'Token';

/**
 * Stateless service for handling the authorization requests.
 */
@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly loginUrl: URL;

  private readonly refreshSecretUrl: URL;

  private readonly resetPasswordUrl: URL;

  private readonly confirmPasswordResetUrl: URL;

  private readonly changePasswordUrl: URL;

  private readonly accountVerificationUrl: URL;

  public constructor(
    appConfig: AppConfigService,
    private readonly httpClient: HttpClient,
    private readonly loginDataMapper: LoginDataMapper,
    private readonly appErrorMapper: AppErrorMapper,
    private readonly userSecretMapper: UserSecretDataMapper,
    private readonly resetPasswordMapper: ResetPasswordMapper,
    private readonly resetPasswordConfirmationMapper: ResetPasswordConfirmationMapper,
    private readonly passwordChangeMapper: PasswordChangeMapper,
  ) {
    this.resetPasswordUrl = new URL('auth/password-reset/', appConfig.apiUrl);
    this.confirmPasswordResetUrl = new URL('auth/password-reset-confirm/', appConfig.apiUrl);
    this.loginUrl = new URL('auth/login/', appConfig.apiUrl);
    this.refreshSecretUrl = new URL('auth/token/refresh/', appConfig.apiUrl);
    this.changePasswordUrl = new URL('users/change_password/', appConfig.apiUrl);
    this.accountVerificationUrl = new URL('auth/account-verify/', appConfig.apiUrl);
  }

  /**
   * Login a user with email and password.
   * @param loginData Login data.
   */
  public login(loginData: Login): Observable<UserSecret> {
    return this.httpClient.post<UserSecretDto>(
      this.loginUrl.toString(),
      this.loginDataMapper.toDto(loginData),
    )
      .pipe(
        map(dto => this.userSecretMapper.fromDto(dto)),
        this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
          this.loginDataMapper,
        ),
      );
  }

  /**
   * Verify account registration.
   * @param verificationToken Account verification token.
   */
  public verifyAccount(
    verificationToken: string,
  ): Observable<UserSecret> {
    const TOKEN_SEPARATOR = '-';
    const [uid, ...tokenTail] = verificationToken.split(TOKEN_SEPARATOR);

    return this.httpClient.post<UserSecretDto>(this.accountVerificationUrl.toString(), {
      uid,
      token: tokenTail.join(TOKEN_SEPARATOR),
    })
      .pipe(
        map(dto => this.userSecretMapper.fromDto(dto)),
        catchHttpErrorResponse(error => throwError(() => new AppError(error.error.detail))),
      );
  }

  /**
   * Appends authorization header to a list of `headers`.
   * @param headers Headers list.
   * @param userSecret User secret.
   */
  public appendAuthorizationHeader(
    headers: HttpHeaders,
    userSecret: UserSecret,
  ): HttpHeaders {
    return headers.set('Authorization', `${AUTH_PREFIX} ${userSecret.token}`);
  }

  /**
   * Refresh user's secret.
   * @param secret Secret data.
   */
  public refreshSecret(
    secret: UserSecret,
  ): Observable<UserSecret> {
    return this.httpClient.post<UserSecretDto>(
      this.refreshSecretUrl.toString(),
      this.userSecretMapper.toDto(secret),
    )
      .pipe(
        map(refreshedSecret =>
          this.userSecretMapper.fromDto({
            ...secret,
            ...refreshedSecret,
          })),
      );
  }

  /**
   * Sends request to reset the password.
   * @param data Data for password reset.
   * @returns Success message.
   */
  public resetPassword(data: PasswordReset.Data): Observable<string> {
    return this.httpClient.post<ResetPasswordConfirmationDto>(
      this.resetPasswordUrl.toString(),
      this.resetPasswordMapper.toDto(data),
    )
      .pipe(
        map(result => result.detail),
        this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
          this.resetPasswordMapper,
        ),
      );
  }

  /**
   * Confirms password reset and applies new passwords to the account.
   * @param data New passwords data.
   * @returns Success message.
   */
  public confirmPasswordReset(
    data: PasswordReset.Confirmation,
  ): Observable<string> {
    return this.httpClient.post<ResetPasswordConfirmationDto>(
      this.confirmPasswordResetUrl.toString(),
      this.resetPasswordConfirmationMapper.toDto(data),
    )
      .pipe(
        map(result => result.detail),
        this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
          this.resetPasswordConfirmationMapper,
        ),
      );
  }

  /**
   * Changes password of current user.
   * @param data Data required for password changing.
   */
  public changePassword(data: PasswordChange): Observable<void> {
    return this.httpClient.post<PasswordChange>(
      this.changePasswordUrl.toString(),
      this.passwordChangeMapper.toDto(data),
    )
      .pipe(
        mapTo(void 0),
        this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
          this.passwordChangeMapper,
        ),
      );
  }
}
